<template>
  <div class="cell-template">
    {{ amount | formatNumber(precision) }} {{ showCurrency && amount ? '€' : '' }}
    <span
      v-if="growth !== undefined"
      :class="[ 'growth', growth > 0 ? 'up' : 'down' ]"
    >
      {{ growth }}%
    </span>
  </div>
</template>

<script>
export default {
  name: 'AmountWithGrowth',
  computed: {
    amount() {
      return this.params?.value?.amount;
    },
    growth() {
      const growth = this.params?.value?.growth;
      return growth ? Math.floor(growth * 1000) / 10 : undefined;
    },
    showCurrency() {
      return this.params?.showCurrency === undefined || this.params?.showCurrency;
    },
    precision() {
      return this.params?.precision || 0;
    },
  },
};
</script>

<style scoped lang="scss">
.cell-template {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .growth {
    margin-left: 10px;
    width: 40px;
    text-align: right;

    &.up {
      color: green;
    }

    &.down {
      color: red;
    }
  }
}
</style>
