<template>
  <div class="cell-template">
    <template>
      {{ amount }}
      <!--        <span-->
      <!--          v-if="growth !== undefined"-->
      <!--          :class="[ 'growth', growth > 0 ? 'up' : 'down' ]"-->
      <!--        >-->
      <!--          {{ growth }}%-->
      <!--        </span>-->
    </template>
  </div>
</template>

<script>
export default {
  name: 'AmountKpisDashboard',
  computed: {
    amount() {
      return this.params.value.toString();
    },
    growth() {
      const column = Number(this.params.colDef?.field);
      const previousColumn = (column - 1) % 100 === 0
        ? column - 89
        : column - 1;

      const currentAmount = this.params.data?.[column]?.amount ?? 0;
      const previousAmount = this.params.data?.[previousColumn]?.amount;

      const growth = previousAmount
        ? (currentAmount - previousAmount) / previousAmount
        : undefined;

      return growth
        ? Math.floor(growth * 1000) / 10
        : undefined;
    },
  },
};
</script>

<style scoped lang="scss">
.cell-template {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .roas {
    text-align: right;

    &.red {
      color: red;
    }

    &.orange {
      color: orange;
    }
  }

  .growth {
    margin-left: 10px;
    width: 40px;
    text-align: right;

    &.up {
      color: green;
    }

    &.down {
      color: red;
    }
  }
}
</style>
