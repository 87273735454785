<template>
  <div>
    <div
      v-if="appList"
      class="filters"
    >
      <div class="field is-floating-label">
        <label class="label"> Application </label>
        <div class="control is-expanded">
          <span class="select is-fullwidth">
            <select
              v-model="selectedApp"
              @change="updateFilter"
            >
              <option
                v-for="app in appList"
                :key="app"
                :value="app"
              >
                {{ app }}
              </option>
            </select>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="defaultHiddenColumns.length"
      class="filters"
    >
      <div class="control is-expanded">
        <b-field
          label="Toggle columns"
          label-position="on-border"
        >
          <b-radio-button
            v-model="displayColumns"
            native-value="show"
            type="is-primary is-light is-outlined"
          >
            Show
          </b-radio-button>

          <b-radio-button
            v-model="displayColumns"
            native-value="hide"
            type="is-primary is-light is-outlined"
          >
            Hide
          </b-radio-button>
        </b-field>
      </div>
    </div>
    <b-loading
      v-model="loading"
      :is-full-page="false"
    />
    <ag-grid-vue
      ref="grid"
      style="width: 100%; height: 81vh"
      class="ag-theme-alpine"
      :column-defs="columns"
      :row-data="data"
      :auto-group-column-def="autoGroupColumn"
      :suppress-agg-func-in-header="true"
      :enable-charts="true"
      :enable-range-selection:="true"
      :group-include-footer="showFooter"
      :group-include-total-footer="showFooter"
      :group-default-expanded="groupDefaultExpanded"
      :always-aggregate-at-root-level="true"
      :suppress-no-rows-overlay="true"
      :suppress-loading-overlay="true"
      :side-bar="sideBar"
      :auto-size-strategy="autoSizeStrategy"
      :default-csv-export-params="defaultCsvExportParams()"
      :default-excel-export-params="defaultExcelExportParams()"
      @grid-ready="onGridReady"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RevenuesGrid',
  components: {
    AgGridVue,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultHiddenColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return null;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showFooter: {
      type: Boolean,
      default() {
        return true;
      },
    },
    sideBar: {
      type: Object,
      default: () => null,
    },
    autoGroupColumn: {
      type: Object,
      default: () => ({
        headerName: 'Group',
        minWidth: 220,
        pinned: true,
      }),
    },
    groupDefaultExpanded: {
      type: Number,
      default: () => 0,
    },
    appList: {
      type: Array,
      default: () => null,
    },
    autoSizeStrategy: {
      type: Object,
      default: () => ({
        type: 'fitCellContents',
      }),
    },
    defaultExportParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    selectedApp: '',
    gridApi: null,
    cache: {},
    isDisplayAllColumns: '',
  }),
  computed: {
    ...mapGetters('filters', ['getGridFilter']),
    displayColumns: {
      get() {
        return this.isDisplayAllColumns;
      },
      set(value) {
        this.isDisplayAllColumns = value;
        const isVisible = value === 'show';
        if (isVisible) {
          this.toggleColumns(true);
        } else {
          this.toggleColumns(false);
        }
        this.$emit('visibility', isVisible);
      },
    },
  },
  beforeMount() {
    this.columnDefs = this.columns;
    this.rowData = this.data;
  },
  methods: {
    ...mapActions('filters', ['filterGrid']),
    defaultCsvExportParams() {
      return {
        skipRowGroups: true,
        ...this.defaultExportParams,
      };
    },
    defaultExcelExportParams() {
      return {
        skipRowGroups: true,
        ...this.defaultExportParams,
      };
    },
    onGridReady(params) {
      this.$emit('ready', params.api);
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      const that = this;
      params.api.addEventListener('filterChanged', (event) => {
        const filterModel = event.api.getFilterModel();

        const filterKeys = Object.keys(filterModel);
        if (filterKeys.includes('lunigroup') || event.afterDataChange === false) {
          that.filterGrid(filterModel);
        }
      });

      const applyFilter = (event) => {
        const hasLuniGroupFilter = event.columnApi.columnController.columnDefs.some((column) => column.colId === 'lunigroup');

        if (hasLuniGroupFilter) {
          event.api.setFilterModel(this.getGridFilter);
        }

        if (event.api.getSideBar()) {
          const filtersToolPanel = event.api.getToolPanelInstance('filters');
          if (filtersToolPanel && hasLuniGroupFilter) {
            filtersToolPanel.expandFilters(['lunigroup']);
          }
        }
      };

      const appFilter = this.getGridFilter?.application?.values?.[0];
      if (appFilter) {
        this.selectedApp = appFilter;
      } else {
        this.selectedApp = 'Scanner';
        this.filterGrid({
          ...this.getGridFilter,
          application: {
            filterType: 'set',
            values: [this.selectedApp],
          },
        });
        this.gridApi.setFilterModel(this.getGridFilter);
      }

      params.api.addEventListener('componentStateChanged', (event) => {
        const cacheDate = this.cache[window.location.path];
        const cacheAge = Math.floor((new Date() - cacheDate) / (1000 * 60 * 60));
        if (cacheAge > 8) {
          window.location.reload();
        }

        applyFilter(event);
      });

      params.api.addEventListener('firstDataRendered', (event) => {
        this.cache[window.location.path] = new Date();
        applyFilter(event);
      });
    },
    toggleColumns(visible) {
      this.columnApi.setColumnsVisible(this.defaultHiddenColumns, visible);
    },
    updateFilter() {
      const filterInstance = this.gridApi.getFilterInstance('application');

      if (filterInstance) {
        filterInstance.setModel({
          filterType: 'set',
          values: [this.selectedApp],
        });

        this.gridApi.onFilterChanged();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  width: auto;
  max-width: 220px;
  margin-bottom: 15px;
}
</style>
