import AmountKpisDashboard from '../components/cellRenderers/AmountKpisDashboard';
import AmountWithGrowth from '../components/cellRenderers/AmountWithGrowth';

import Filters from '../components/Filters';
import Grid from '../components/Grid';
import { KPIsMoney, KPIsPercent, KPIsWithNegativeSign } from '../data/kpis';

export default {
  components: {
    AmountKpisDashboard,
    AmountWithGrowth,
    Filters,
    Grid,
  },
  methods: {
    calculateGrowth(past, current) {
      return Math.abs((past - current) / past) * (current < past ? -1 : 1);
    },
    withLeadingZero(number) {
      return `0${number}`.slice(-2);
    },
    formatKpi(rawValue, kpi, isDiff = false) {
      if (!rawValue) {
        return undefined;
      }

      const isPercentKpis = KPIsPercent.includes(kpi);
      const isMoneyKpis = KPIsMoney.some((kpiLabel) => kpi.includes(kpiLabel));

      const precision = (isPercentKpis || (isMoneyKpis && Math.abs(rawValue) < 100)) ? 2 : 0;
      const suffix = (isPercentKpis && isDiff && ' bps')
        || (isPercentKpis && ' %')
        || (isMoneyKpis && ' €')
        || '';

      const value = isPercentKpis
        ? rawValue * 100
        : rawValue;
      const amount = value
        .toFixed(precision)
        .replace(/\.0+$/, '')
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

      return `${amount} ${suffix}`.trim();
    },
    formatCompletion(value) {
      if (!value) {
        return undefined;
      }

      const sign = value > 0
        ? '+'
        : '-';

      const amount = Math.abs(value * 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

      return `${sign} ${amount} %`;
    },
    cellStyle(params) {
      if (!params?.value) {
        return {};
      }

      const key = (params?.data?.kpi ?? params?.node?.key);
      const positiveSign = KPIsWithNegativeSign.includes(key) ? '-' : '+';
      const negativeSign = KPIsWithNegativeSign.includes(key) ? '+' : '-';

      if (params?.value?.toString()?.startsWith(negativeSign)) {
        return { color: 'red' };
      }
      if (params?.value?.toString()?.startsWith(positiveSign)) {
        return { color: 'green' };
      }

      return { color: 'orange' };
    },
  },
};
